import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';

import { displayErrorMessage } from '../../../providers/snackbar';
import { getErrorTranslation } from '../../../utils/language';
import axiosAPI from '../../axiosApi';

const initialState = {
  status: 'idle',
  formId: null,
  animalType: null,
  quoteId: null,
  preventionOrInsurance: null,
  firstName: '',
  lastName: '',
  quoteType: null,
  birthday: null,
  animals: [],
  isManualAddress: false,
  address: {},
  formattedAddress: '',
  guard: {},
  email: '',
  phone: '',
  prices: null,
  rates: {},
  supplement: 0,
  hasFormerContract: false,
  quotePricing: null,
  affiliate: null,
  start: null,
};

export const checkUserEmail = createAsyncThunk(
  'pricing/checkUserEmail',
  async () => {
    try {
      const response = await axiosAPI.get(`checkUserEmail/`);
      return response?.data;
    } catch (e) {
      displayErrorMessage(getErrorTranslation(e?.response?.data));
      return getErrorTranslation(e?.response?.data);
    }
  },
);

export const getPricing = createAsyncThunk(
  'pricing/quote',
  async (quoteId) => {
    try {
      const response = await axiosAPI.get(`quote/${quoteId}/`);
      return response?.data;
    } catch (e) {
      displayErrorMessage(getErrorTranslation(e?.response?.data));
      return getErrorTranslation(e?.response?.data);
    }
  },
);

export const formPricing = createAsyncThunk(
  'pricing/form',
  async (data, { getState }) => {
    const state = getState().pricing;
    try {
      return await axiosAPI.post('form/', {
        id: state.formId,
        ...data,
      });
    } catch (e) {
      displayErrorMessage(getErrorTranslation(e?.response?.data));
      return getErrorTranslation(e?.response?.data);
    }
  },
);

export const completeFormPricing = createAsyncThunk(
  'pricing/completeForm',
  async (data, { getState }) => {
    const state = getState().pricing;
    try {
      const response = await axiosAPI.post('completeForm/', {
        id: state.formId,
        ...data,
      });
      return response;
    } catch (e) {
      displayErrorMessage(getErrorTranslation(e?.response?.data));
      return getErrorTranslation(e?.response?.data);
    }
  },
);

export const updatePrice = createAsyncThunk(
  'pricing/updatePrice',
  async (data) => {
    try {
      const response = await axiosAPI.post('updatePrice/', data);
      return {
        prices:response.data,
        start: data.start
      }
    } catch (e) {
      displayErrorMessage(getErrorTranslation(e?.response?.data));
      return getErrorTranslation(e?.response?.data);
    }
  },
);

export const pricingSlice = createSlice({
  name: 'pricing',
  initialState,
  reducers: {
    reset: (state) => {
      state.quoteId = null;
      state.formId = null;
      state.prices = null;
      state.animals = [];
      state.birthday = null;
      state.start = null;
      state.isManualAddress = false;
    },
    selectFirstName: (state, action) => {
      state.firstName = action.payload;
    },
    selectLastName: (state, action) => {
      state.lastName = action.payload;
    },
    selectAnimalType: (state, action) => {
      state.animalType = action.payload;
    },
    selectPreventionOrInsurance: (state, action) => {
      state.quoteType = action.payload;
    },
    selectQuotePricing: (state, action) => {
      state.quotePricing = action.payload;
    },
    selectAnimals: (state, action) => {
      state.animals = action.payload;
    },
    selectAddress: (state, action) => {
      state.address = action.payload;
    },
    selectManualAdress: (state, action) => {
      state.isManualAddress = action.payload;
    },
    selectBirthday: (state, action) => {
      state.birthday = action.payload;
    },
    selectGuard: (state, action) => {
      state.guard = action.payload;
    },
    selectEmail: (state, action) => {
      state.email = action.payload;
    },
    selectPhone: (state, action) => {
      state.phone = action.payload;
    },
    selectFormattedAddress: (state, action) => {
      state.formattedAddress = action.payload;
    },
    selectSupplement: (state, action) => {
      state.supplement = action.payload;
    },
    selectAvailableGuarantees: (state, action) => {
      state.availableGuarantees = action.payload;
    },
  },
  extraReducers: {
    [getPricing.pending]: (state) => {
      state.status = 'loading';
    },
    [getPricing.fulfilled]: (state, { payload }) => {
      const { quote, user, affiliate } = payload;
      state.status = 'succeeded';
      state.quoteId = quote.id;
      state.prices = quote.price;
      state.animals = quote.animals;
      state.rates = quote.rates;
      state.animalType = quote.quoteType;
      state.address = user.address;
      state.isManualAddress = user.isManualAddress;
      state.birthday = user.birthday;
      state.email = user.email;
      state.phone = user.phone;
      state.firstName = user.first_name;
      state.lastName = user.last_name;
      state.supplement = 0;
      state.hasFormerContract = quote.hasFormerContract;
      state.affiliate = affiliate;
      state.availableGuarantees = quote?.availableGuarantees || [];
      state.start = quote?.start;
    },
    [getPricing.rejected]: (state, { payload }) => {
      state.status = 'failed';
    },
    [formPricing.pending]: (state) => {
      state.status = 'loading';
    },
    [formPricing.fulfilled]: (state, { payload }) => {
      state.status = 'succeeded';
      state.formId = payload.data?.id;
    },
    [formPricing.rejected]: (state, { payload }) => {
      state.status = 'failed';
    },
    /////////////////////////////////////
    [completeFormPricing.pending]: (state) => {
      state.status = 'loading';
    },
    [completeFormPricing.rejected]: (state, { payload }) => {
      state.status = 'failed';
    },
    [completeFormPricing.fulfilled]: (state, { payload }) => {
      state.status = 'completed';
      state.quoteId = payload?.data?.id;
      state.prices = payload?.data?.prices;
    },
    /////////////////////////////////////
    [updatePrice.pending]: (state) => {
      state.status = 'loading';
    },
    [updatePrice.rejected]: (state) => {
      state.status = 'failed';
    },
    [updatePrice.fulfilled]: (state, { payload }) => {
      state.status = 'completed';
      state.prices = payload.prices;
      if ( payload.start !== undefined) {
        state.start = payload.start
      }
    },
  },
});

// Action creators are generated for each case reducer function
export const {
  reset,
  selectFirstName,
  selectLastName,
  selectAnimalType,
  selectAnimals,
  selectGuard,
  selectBirthday,
  selectAddress,
  selectEmail,
  selectPhone,
  selectFormattedAddress,
  selectSupplement,
  selectPreventionOrInsurance,
  selectQuotePricing,
  selectManualAdress,
  selectAvailableGuarantees,
  selectStart,
} = pricingSlice.actions;

export default pricingSlice.reducer;
