import { FileOpener } from '@capacitor-community/file-opener';
import { Directory, Filesystem } from '@capacitor/filesystem';
import CalendarTodayIcon from '@mui/icons-material/CalendarToday';
import CreditCardIcon from '@mui/icons-material/CreditCard';
import LockIcon from '@mui/icons-material/Lock';
import {
  Button,
  InputAdornment,
  Stack,
  TextField,
} from '@mui/material';
import Checkbox from '@mui/material/Checkbox';
import CircularProgress from '@mui/material/CircularProgress';
import FormControl from '@mui/material/FormControl';
import FormControlLabel from '@mui/material/FormControlLabel';
import FormGroup from '@mui/material/FormGroup';
import Link from '@mui/material/Link';
import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import Typography from '@mui/material/Typography';
import { useTheme } from '@mui/styles';
import {
  CardCvcElement,
  CardExpiryElement,
  CardNumberElement,
  useElements,
  useStripe,
} from '@stripe/react-stripe-js';
import write_blob from 'capacitor-blob-writer';
import React, { useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { v4 as uuidv4 } from 'uuid';

import { displayErrorMessage } from '../../../providers/snackbar';
import { getErrorTranslation } from '../../../utils/language';
import { getAppName } from '../../../utils/language';
import axiosAPI from '../../axiosApi';
import StripeInput from './StripeInput';

const useOptions = (theme) => {
  const options = useMemo(
    () => ({
      style: {
        base: {
          color: theme.palette.primary.main,
          letterSpacing: '0.025em',
          fontFamily: 'Source Code Pro, monospace',
          '::placeholder': {
            color: '#aab7c4',
          },
          fontSize: theme.fontSize,
        },
        invalid: {
          color: theme.palette.error.main,
        },
      },
    }),
    [
      theme.fontSize,
      theme.palette.primary.main,
      theme.palette.error.main,
    ],
  );

  return options;
};

const isMobileApp = process.env.REACT_APP_IS_MOBILE_APP === 'true';
const fileMobilePrefixVersion = uuidv4();

const SplitForm = ({
  affiliate,
  price,
  setter,
  id,
  onlyPayment,
  setCloseable,
}) => {
  const { t } = useTranslation(null, {
    keyPrefix: 'quote.creditCardElement',
  });
  const theme = useTheme();
  const stripe = useStripe();
  const [processing, setProcessing] = useState(false);
  const setPaymentStatus = setter;
  const elements = useElements();
  const options = useOptions(theme);

  const [useDefault, setUseDefault] = useState('useDefault');

  const [checked, setChecked] = React.useState(false);

  const [customError, setCustomError] = React.useState();

  const [endPeriod, setEndPeriod] = React.useState(null);
  const [firstPrice, setFirstPrice] = React.useState(null);
  const [priceTotal, setPriceTotal] = React.useState(price);

  const handleChange = (event) => {
    setChecked(event.target.checked);
  };

  React.useEffect(() => {
    if (processing) {
      setCloseable(false);
    }
  }, [processing, setCloseable]);

  React.useEffect(() => {
    if (onlyPayment) {
      axiosAPI
        .post('addItem/', id)
        .then((response) => {
          setEndPeriod(response.data['end_periode']);
          setPriceTotal(response.data['price']);
          setFirstPrice(response.data['firstPrice']);
        })
        .catch((e) => displayErrorMessage(getErrorTranslation()));
    }
  }, [onlyPayment, id]);

  const downloadCG = (id) => {
    axiosAPI
      .get(`getCG/${id}/`, { responseType: 'blob' })
      .then((response) => {
        displayFile(response, 'CG');
      })
      .catch((e) =>
        displayErrorMessage(getErrorTranslation(e?.response?.data)),
      );
  };

  const checkAndDeleteIfFileExists = async (fileName) =>
    Filesystem.readFile({
      path: fileName,
      directory: Directory.Documents,
    })
      .then((data) => {
        Filesystem.deleteFile({
          path: fileName,
          directory: Directory.Documents,
        })
          .then(() =>
            console.log('File has been deleted : ', fileName),
          )
          .catch((error) =>
            console.log('Unable to delete file : ', error),
          );
      })
      .catch((error) => {
        console.log('File does not exists : ', error);
      });

  const displayFile = async (response, file) => {
    if (isMobileApp) {
      const fileName = `${file}-${fileMobilePrefixVersion}.pdf`;
      await checkAndDeleteIfFileExists(fileName);
      write_blob({
        path: fileName,
        directory: Directory.Documents,
        blob: response.data,
        fast_mode: true,
        recursive: true,
      })
        .then(() => {
          return Filesystem.getUri({
            path: fileName,
            directory: Directory.Documents,
          });
        })
        .then(({ uri }) => {
          return FileOpener.open({
            filePath: uri,
            contentType: 'application/pdf',
          });
        })
        .catch((error) => {
          alert(t('alertMessage'));
        });
    } else {
      let url = window.URL.createObjectURL(response.data);
      let a = document.createElement('a');
      a.href = url;
      a.target = '_blank';
      a.click();
    }
  };

  const downloadCP = (data) => {
    axiosAPI
      .get(`getCP/${data}/`, { responseType: 'blob' })
      .then((response) => {
        displayFile(response, 'CP');
      })
      .catch((e) =>
        displayErrorMessage(getErrorTranslation(e?.response?.data)),
      );
  };

  const downloadPC = () => {
    axiosAPI
      .get(
        `getPC/?lang=${
          process.env.REACT_APP_LANGUAGE
        }&franchise=${getAppName()}`,
        { responseType: 'blob' },
      )
      .then((response) => {
        displayFile(response, 'PC');
      })
      .catch((e) =>
        displayErrorMessage(getErrorTranslation(e?.response?.data)),
      );
  };

  const [state, setState] = React.useState({
    cardNumberComplete: false,
    expiredComplete: false,
    cvcComplete: false,
    cardNumberError: null,
    expiredError: null,
    cvcError: null,
  });

  const onElementChange =
    (field, errorField) =>
    ({ complete, error = { message: null } }) => {
      setState({
        ...state,
        [field]: complete,
        [errorField]: error.message,
      });
    };

  const {
    cardNumberComplete,
    expiredComplete,
    cvcComplete,
    cardNumberError,
    expiredError,
    cvcError,
  } = state;
  const handleSubmit = async (event) => {
    event.preventDefault();

    if (!stripe || !elements) {
      // Stripe.js has not loaded yet. Make sure to disable
      // form submission until Stripe.js has loaded.
      return;
    }

    setProcessing(true);
    setPaymentStatus('processing');

    let paymentMethod = null;

    try {
      if (onlyPayment && useDefault !== 'useDefault') {
        // Create payment method
        paymentMethod = await stripe.createPaymentMethod({
          type: 'card',
          card: elements.getElement(CardNumberElement),
          metadata: { type: 'payment' },
        });

        const {
          data: { client_secret },
        } = await axiosAPI.post('updatePaymentMethod/', {
          type: 'card',
        });

        const { setupIntent, error } = await stripe.confirmCardSetup(
          client_secret,
          {
            payment_method: paymentMethod.paymentMethod.id,
          },
        );

        if (error) {
          setCustomError(true);
          setPaymentStatus(error.type);
          return;
        } else {
          switch (setupIntent?.status) {
            case 'succeeded':
              await axiosAPI.post(
                'validatePaymentMethod/',
                paymentMethod.paymentMethod.id,
              );
              break;
            case 'processing':
              break;
            case 'requires_payment_method':
              setProcessing(false);
              setCustomError(true);
              break;
            default:
              setProcessing(false);
              setCustomError(true);
              break;
          }
        }
      } else if (!onlyPayment) {
        // Create payment method
        paymentMethod = await stripe.createPaymentMethod({
          type: 'card',
          card: elements.getElement(CardNumberElement),
          metadata: { type: 'payment' },
        });
      }

      if (!customError) {
        const response = await axiosAPI.post('paymentIntents/', {
          formId: id,
          paymentMethodId: paymentMethod?.paymentMethod.id,
          type: paymentMethod?.type,
        });

        if (
          !onlyPayment &&
          response.data['intent_status'] === 'setup_card'
        ) {
          const { setupIntent, error } =
            await stripe.confirmCardSetup(
              response.data.client_secret,
              { payment_method: paymentMethod.paymentMethod.id },
            );
          if (error) {
            setPaymentStatus(error.type);
          } else {
            setPaymentStatus(setupIntent.status);
          }
        } else if (
          !onlyPayment ||
          (typeof response.data === 'object' &&
            'intent_status' in response.data &&
            response.data['intent_status'] === 'requires_action')
        ) {
          const { paymentIntent, error } =
            await stripe.confirmCardPayment(
              response.data.client_secret,
              { setup_future_usage: 'off_session' },
            );
          if (error) {
            setPaymentStatus(error.type);
          } else {
            setPaymentStatus(paymentIntent.status);
          }
        } else {
          setPaymentStatus(response.data);
        }
      }
    } catch (e) {
      setPaymentStatus('failed');
      displayErrorMessage(getErrorTranslation(e?.response?.data));
    }
    setProcessing(false);
  };

  if (onlyPayment && firstPrice === null) {
    return <CircularProgress sx={{ margin: 'auto' }} />;
  }

  return (
    <form onSubmit={handleSubmit}>
      <Stack spacing={4}>
        {onlyPayment && (
          <FormControl
            sx={{ display: processing ? 'none' : 'block' }}
          >
            <RadioGroup
              aria-labelledby="demo-radio-buttons-group-label"
              name="radio-buttons-group"
              value={useDefault}
              onChange={(event) => setUseDefault(event.target.value)}
            >
              <FormControlLabel
                style={{ marginBottom: '10px', marginRight: 0 }}
                value={'useDefault'}
                control={<Radio />}
                label={
                  <Typography
                    fontSize="15px"
                    sx={{
                      textAlign: 'left',
                      textDecoration: useDefault
                        ? 'none'
                        : 'line-through',
                    }}
                  >
                    {t('useMyDefaultPaymentMethod')}
                  </Typography>
                }
              />
              <FormControlLabel
                style={{ marginRight: 0 }}
                value={'editDefault'}
                control={<Radio />}
                label={
                  <Typography
                    fontSize="15px"
                    sx={{
                      textAlign: 'left',
                      textDecoration: useDefault
                        ? 'none'
                        : 'line-through',
                    }}
                  >
                    {t('editMyDefaultPaymentMethod')}
                  </Typography>
                }
              />
            </RadioGroup>
          </FormControl>
        )}

        <Stack
          spacing={4}
          sx={{ display: processing ? 'none' : 'block' }}
        >
          {onlyPayment && useDefault === 'useDefault' ? (
            ''
          ) : (
            <>
              <TextField
                label={t('labelCardNumber')}
                onChange={onElementChange(
                  'cardNumberComplete',
                  'cardNumberError',
                )}
                name="ccnumber"
                variant="outlined"
                fullWidth
                InputLabelProps={{ shrink: true }}
                InputProps={{
                  inputComponent: StripeInput,
                  inputProps: {
                    component: CardNumberElement,
                    options: { options },
                  },
                  startAdornment: (
                    <InputAdornment position="start">
                      <CreditCardIcon />
                    </InputAdornment>
                  ),
                }}
              />
              <Stack direction="row" spacing={0}>
                <TextField
                  label={t('labelExpirationCard')}
                  onChange={onElementChange(
                    'expiredComplete',
                    'expiredError',
                  )}
                  name="cexpiryElement"
                  variant="outlined"
                  // required
                  fullWidth
                  InputLabelProps={{ shrink: true }}
                  InputProps={{
                    inputComponent: StripeInput,
                    inputProps: {
                      component: CardExpiryElement,
                      options: { options },
                    },
                    startAdornment: (
                      <InputAdornment position="start">
                        <CalendarTodayIcon />
                      </InputAdornment>
                    ),
                  }}
                />

                <TextField
                  label={t('labelCVC')}
                  onChange={onElementChange(
                    'cvcComplete',
                    'cvcError',
                  )}
                  name="ccvcElement"
                  variant="outlined"
                  fullWidth
                  InputLabelProps={{ shrink: true }}
                  InputProps={{
                    inputComponent: StripeInput,
                    inputProps: {
                      options: { options },
                      component: CardCvcElement,
                    },
                    startAdornment: (
                      <InputAdornment position="start">
                        <LockIcon />
                      </InputAdornment>
                    ),
                  }}
                />
              </Stack>
            </>
          )}
          <FormGroup>
            <FormControlLabel
              control={
                <Checkbox checked={checked} onChange={handleChange} />
              }
              sx={{ maxWidth: '400px' }}
              label={
                <Typography
                  fontSize="12px"
                  sx={{ textAlign: 'left' }}
                >
                  {t('checkboxAcceptCVG.text1')}
                  <Link
                    onClick={(e) => {
                      e.preventDefault();
                      downloadCP(id);
                    }}
                  >
                    {t('checkboxAcceptCVG.text2')}
                  </Link>
                  {t('checkboxAcceptCVG.text3')}
                  <Link
                    onClick={(e) => {
                      e.preventDefault();
                      downloadCG(id);
                    }}
                  >
                    {t('checkboxAcceptCVG.text4')}
                  </Link>
                  {t('checkboxAcceptCVG.text5')}
                  <Link
                    onClick={(e) => {
                      e.preventDefault();
                      downloadPC();
                    }}
                  >
                    {t('checkboxAcceptCVG.text6')}
                  </Link>
                </Typography>
              }
            />
          </FormGroup>

          <div style={{ textAlign: 'center' }}>
            <Button
              variant={'contained'}
              type="submit"
              style={{
                width: '160px',
                margin: 'auto',
                marginBottom: '10px',
                height: '50px',
                clipPath:
                  'polygon(2px 0, 0 62px, 337px 60px, 330px 3px, 2px 0)',
                borderRadius: 0,
              }}
              disabled={
                !checked ||
                (!onlyPayment &&
                  (!stripe ||
                    cardNumberError ||
                    expiredError ||
                    cvcError ||
                    !(
                      cardNumberComplete &&
                      expiredComplete &&
                      cvcComplete
                    )))
              }
            >
              {t('payButton')}
              {firstPrice !== null
                ? (firstPrice > 0
                    ? firstPrice.toFixed(2).replace('.', ',')
                    : '0') + ' '
                : affiliate > 0
                ? '0 '
                : price?.toFixed(2).replace('.', ',') + ' '}
              €
            </Button>
            {affiliate > 0 && (
              <Typography
                fontSize="12px"
                sx={{ textAlign: 'center' }}
              >
                {t('affiliatePeriod1', { affiliate })}
                {price?.toFixed(2).replace('.', ',')}{' '}
                {t('affiliatePeriod2')}
              </Typography>
            )}
            {firstPrice !== null && (
              <Typography fontSize="12px" sx={{ textAlign: 'left' }}>
                {t('firstPricePeriod1')}{' '}
                {new Date().toLocaleDateString()}{' '}
                {t('firstPricePeriod2', { endPeriod })}
                {priceTotal.toFixed(2).replace('.', ',')}
                {t('firstPricePeriod3')}
              </Typography>
            )}
          </div>
        </Stack>
        <div
          style={{
            textAlign: 'center',
            display: processing ? 'block' : 'none',
          }}
        >
          <CircularProgress sx={{ margin: 'auto' }} />
          <Typography sx={{ mt: 2, mb: 1, fontSize: '22px' }}>
            {t('paymentProgress')}
          </Typography>
        </div>
      </Stack>
    </form>
  );
};

export default SplitForm;
