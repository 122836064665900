import { yupResolver } from '@hookform/resolvers/yup';
import {
  Box,
  Grid,
  Hidden,
  Link,
  TextField,
  Typography,
} from '@mui/material';
import Checkbox from '@mui/material/Checkbox';
import CircularProgress from '@mui/material/CircularProgress';
import FormControlLabel from '@mui/material/FormControlLabel';
import { makeStyles } from '@mui/styles';
import { isPhoneNumberValid } from '@operatech1/validators';
import _ from 'lodash';
import React, { useEffect } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { Link as RouterLink } from 'react-router-dom';
import * as yup from 'yup';

import { displayErrorMessage } from '../../../../providers/snackbar';
import pricing_asset_5 from '../../../../static/pricing/pricing-asset-5.svg';
import pricing_asset_at from '../../../../static/pricing/pricing-asset-a.svg';
import pricing_asset_mobile_7 from '../../../../static/pricing/pricing-asset-mobile-7.svg';
import pricing_asset_phone from '../../../../static/pricing/pricing-asset-phone.svg';
import theme from '../../../../theme';
import {
  getAppName,
  getErrorTranslation,
  isAlcampo,
} from '../../../../utils/language';
import ButtonCustom from '../../../atoms/Button';
import axiosAPI from '../../../axiosApi';
import { GUARD } from '../constants';
import { selectEmail, selectPhone } from '../pricingSlice';

const useStyles = makeStyles((theme) => ({
  root: {
    width: '100%',
    paddingTop: 80,
    paddingLeft: 10,
    paddingRight: 10,
    [theme.breakpoints.down('sm')]: {
      paddingTop: 10,
    },
  },
  form: {
    margin: 5,
  },
  text_field_label: {
    color: theme.palette.primary.main,
  },
  text_field: {
    color: theme.palette.primary.main,
    '&:before': {
      color: theme.palette.primary.main,
    },
    '&:after': {
      color: theme.palette.primary.main,
    },
  },
  text_field_label_error: {
    color: theme.palette.error.main + ' !important',
  },
  text_field_error: {
    color: theme.palette.error.main + ' !important',
    '&:before': {
      borderColor: theme.palette.error.main + ' !important',
    },
    '&:after': {
      borderColor: theme.palette.error.main + ' !important',
    },
  },
  title_text: {
    fontSize: '2.1rem',
    [theme.breakpoints.down('lg')]: {
      fontSize: '1.7rem',
    },
    [theme.breakpoints.down('md')]: {
      fontSize: '1.4rem',
    },
    [theme.breakpoints.down('sm')]: {
      fontSize: '1.2rem',
      backgroundImage: `url(${pricing_asset_mobile_7})`,
      backgroundRepeat: 'no-repeat',
      backgroundPosition: 'center',
      padding: 20,
    },
  },
  title_text_small: {
    [theme.breakpoints.up('ss')]: {
      display: 'none',
    },
  },
  title_text_normal: {
    [theme.breakpoints.down('ss')]: {
      display: 'none',
    },
  },
  title_container: {
    display: 'flex',
    justifyContent: 'center',
    width: '100%',
    marginBottom: 30,
    [theme.breakpoints.down('sm')]: {
      marginBottom: 0,
    },
  },
  input_container: {
    marginTop: 20,
    [theme.breakpoints.down('lg')]: {
      marginTop: 20,
    },
  },
  submit_btn: {
    marginTop: 60,
    marginBottom: 20,
    [theme.breakpoints.down('sm')]: {
      marginTop: 30,
    },
  },
  date_picker: {
    maxWidth: 206,
    color: theme.palette.primary.main,
  },
}));

const schema = yup.object().shape({
  email: yup.string().email().required(),
  phone: yup
    .string()
    .test('isPhoneNumberValid', '', (val) =>
      !_.isNil(val)
        ? isPhoneNumberValid(val, 'FR') ||
          isPhoneNumberValid(val, 'ES')
        : false,
    )
    .required(),
});

export default function Pricing({ step, handleNext, isLoading }) {
  const { t } = useTranslation(null, {
    keyPrefix: 'pricing.email',
  });
  const pricing = useSelector((state) => state.pricing);
  const { formId, firstName, animalType, email, phone, birthday } =
    pricing;
  const dispatch = useDispatch();

  const { control, formState, handleSubmit, watch } = useForm({
    resolver: yupResolver(schema),
    mode: 'onChange',
    reValidateMode: 'onChange',
  });
  const [emailExists, setEmailExists] = React.useState(false);
  const { isValid, errors } = formState;
  const emailError = errors.email;
  const emailValue = watch('email');
  const [hasFormerContract, setHasFormerContract] =
    React.useState(false);
  const handleChangeHasFormerContract = (event) => {
    setHasFormerContract(event.target.checked);
  };
  useEffect(() => {
    if (emailError || (emailValue?.trim()?.length || 0) <= 2) return;
    async function fetchData() {
      try {
        const response = await axiosAPI.post('checkUserEmail/', {
          email: emailValue,
        });
        setEmailExists(response.data);
      } catch (e) {
        displayErrorMessage(getErrorTranslation());
      }
    }
    fetchData();
  }, [emailError, emailValue]);
  const onSubmit = (data) => {
    if (isLoading) return;
    dispatch(selectEmail(data.email));
    dispatch(selectPhone(data.phone));
    handleNext({
      step,
      id: formId,
      email: data.email.trim().toLowerCase(),
      phone: data.phone.trim(),
      birthday,
      hasFormerContract,
    });
  };
  const classes = useStyles();
  return (
    <form onSubmit={handleSubmit(onSubmit)} autoComplete="off">
      <Box
        sx={{
          display: {
            xs: 'none',
            sm: animalType === GUARD ? 'none' : 'block',
          },
          position: 'absolute',
          zIndex: -1,
        }}
        className={'pricing_asset_class'}
      >
        <img
          alt="svg"
          src={pricing_asset_5}
          style={{ width: '100%', height: '100%' }}
        />
      </Box>
      <Grid
        container
        direction="column"
        justifyContent="space-between"
        alignItems="center"
        className={classes.root}
        spacing={0}
      >
        <Grid item xs={12} className={classes.title_container}>
          <Typography
            color={'primary'}
            variant={'h4'}
            className={classes.title_text}
          >
            <div className={classes.title_text_normal}>
              {t('title1', { firstName })}
              <br />
              {t('title2')}
              <br /> {t('title3')}
            </div>
            <div className={classes.title_text_small}>
              {t('mobile.title1', { firstName })}
              <span style={{ display: 'inline-block' }}>
                {t('mobile.title2')}
              </span>
            </div>
          </Typography>
        </Grid>
        <Grid
          item
          xs={12}
          display="flex"
          className={classes.input_container}
        >
          <Box
            component="img"
            src={pricing_asset_at}
            style={{ marginRight: '20px' }}
            sx={{ width: { xs: '30px', sm: '40px' } }}
          />
          <Grid
            container
            direction="column"
            justifyContent="space-between"
            alignItems="center"
          >
            <Controller
              control={control}
              name="email"
              defaultValue={email}
              render={({ field }) => (
                <TextField
                  label={t('labelEmail')}
                  type={'email'}
                  variant="standard"
                  InputLabelProps={{
                    className: errors?.email
                      ? classes.text_field_label_error
                      : classes.text_field_label,
                  }}
                  InputProps={{
                    className: errors?.email
                      ? classes.text_field_error
                      : classes.text_field,
                  }}
                  className={classes.form}
                  {...field}
                />
              )}
            />
          </Grid>
        </Grid>
        <Grid
          item
          xs={12}
          display="flex"
          alignItems="center"
          className={classes.input_container}
        >
          <Box
            component="img"
            src={pricing_asset_phone}
            style={{ marginRight: '20px' }}
            sx={{ width: { xs: '30px', sm: '40px' } }}
          />
          <Grid
            container
            direction="column"
            justifyContent="space-between"
            alignItems="center"
          >
            <Controller
              control={control}
              name="phone"
              defaultValue={phone}
              render={({ field }) => (
                <TextField
                  label={t('labelPhone')}
                  variant="standard"
                  InputLabelProps={{
                    className: errors?.phone
                      ? classes.text_field_label_error
                      : classes.text_field_label,
                  }}
                  InputProps={{
                    className: errors?.phone
                      ? classes.text_field_error
                      : classes.text_field,
                  }}
                  className={classes.form}
                  {...field}
                />
              )}
            />
          </Grid>
        </Grid>
        <Grid
          item
          xs={12}
          style={{
            marginTop: '30px',
            paddingLeft: '20px',
            paddingRight: '20px',
          }}
        >
          <FormControlLabel
            style={{
              maxWidth: '470px',
              textAlign: 'left',
              color: theme.palette.primary.main,
            }}
            value="end"
            defaultValue={false}
            control={
              <Checkbox
                checked={hasFormerContract}
                onChange={handleChangeHasFormerContract}
                color="primary"
              />
            }
            label={
              <Typography
                fontSize="15px"
                style={{
                  color: errors?.hasFormerContract
                    ? theme.palette.error.main
                    : 'unset',
                }}
              >
                {isAlcampo()
                  ? t('alcampo.resiliate1')
                  : t('resiliate1')}
                <span style={{ display: 'inline-block' }}>
                  {t('resiliate2')}
                </span>
              </Typography>
            }
            labelPlacement="end"
          />
          <br />
        </Grid>
        {/* <Grid item xs={12}
                      style={{
                          marginTop: '30px',
                          paddingLeft: '20px',
                          paddingRight: '20px',
                      }}>
                    <Controller control={control}
                                name='validate'
                                defaultValue=""
                                render={({field}) =>
                                    <FormControlLabel style={{
                                        pointerEvents: "none",
                                        maxWidth: '420px',
                                        textAlign: 'left',
                                        color: theme.palette.primary.main
                                    }}
                                                      value="end"
                                                      control={
                                                          <Checkbox style={{ pointerEvents: "auto" }}
                                                                    color="primary"/>}
                                                      label={
                                                          <Typography fontSize='15px'
                                                                      style={{ color: errors?.validate ? theme.palette.error.main : 'unset'}}>
                                                              Acceptation de nos <Link component={RouterLink} to="/termsAndConditions" style={{ pointerEvents: "auto" }}>Conditions générales</Link> et de notre <Link component={RouterLink} to="/privacyPolicy" style={{ pointerEvents: "auto" }}>Politique de confidentialité</Link>
                                                          </Typography>
                                                      }
                                                      labelPlacement="end"
                                                      {...field}/>
                                }/>
                    <br/>
                </Grid>*/}

        <Grid item xs={12} className={classes.submit_btn}>
          {emailExists ? (
            <Typography
              color="primary"
              style={{ fontSize: '1.1em', marginBottom: '15px' }}
              sx={{
                marginLeft: { xs: '10px', sm: '100px' },
                marginRight: { xs: '10px', sm: '100px' },
              }}
            >
              {t('existEmail.title1')}
              <span style={{ display: 'inline-block' }}>
                {t('existEmail.title2')}
              </span>
              <Hidden smDown>
                <br />
              </Hidden>{' '}
              {t('existEmail.subtitle1')}
              <Link
                component={RouterLink}
                to="/account/"
                style={{ pointerEvents: 'auto' }}
              >
                {t('existEmail.subtitle2', { app: getAppName() })}
              </Link>{' '}
              {t('existEmail.subtitle3')}
              <span style={{ display: 'inline-block' }}>
                {t('existEmail.subtitle4')}
              </span>
            </Typography>
          ) : null}
          <ButtonCustom
            type="submit"
            width={199}
            height={47}
            disabled={!isValid || emailExists}
          >
            {!isLoading ? (
              t('calculButton')
            ) : (
              <CircularProgress
                sx={{
                  color: theme.palette.secondary.main,
                  display: 'block',
                }}
                thickness={5.0}
                size="25px"
              />
            )}
          </ButtonCustom>
        </Grid>
      </Grid>
    </form>
  );
}
