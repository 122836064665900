import CheckCircleOutlineIcon from '@mui/icons-material/CheckCircleOutline';
import ErrorOutlineIcon from '@mui/icons-material/ErrorOutline';
import { Hidden } from '@mui/material';
import Step from '@mui/material/Step';
import StepLabel from '@mui/material/StepLabel';
import Stepper from '@mui/material/Stepper';
import Typography from '@mui/material/Typography';
import makeStyles from '@mui/styles/makeStyles';
import React, { useContext, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';

import { Context } from '../../../../layouts/Account';
import { blue } from '../../../../theme';
import Button from '../../../atoms/Button';
import Widget from '../../../atoms/Widget';
import { getAccount } from '../../login/userSlice';
import Step1 from '../refunds/Step1';
import Step2 from '../refunds/Step2';
import Step3 from '../refunds/Step3';
import Step5 from '../refunds/Step5';
import Step6 from '../refunds/Step6';
import {
  clearState,
  clearStatus,
  refundSend,
  selectAnimal,
  selectCare,
  selectContext,
  selectCost,
  selectIsSurgery,
  selectType,
} from '../refundSlice';

const useStyles = makeStyles((theme) => ({
  text: {
    fontSize: 13,
    marginTop: 10,
    margin: 'auto',
    opacity: 0.3,
  },
  content: {
    width: '100%',
    height: '100%',
    '&>img': {
      width: '100%',
      height: '100%',
    },
    textAlign: '-webkit-center',
  },
  check: {
    fontSize: '10rem',
  },
  stepNum: {
    '& text': {
      fill: 'white',
    },
  },
  stepper: {
    marginTop: '10px',
    marginBottom: '10px',
  },
}));

export default function Remboursements() {
  const { t } = useTranslation(null, {
    keyPrefix: 'accountPage.quote',
  });
  const classes = useStyles();
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const [activeStep, setActiveStep] = useState(0);

  const status = useSelector((state) => state.refund.status);
  const [files, setFiles] = useState([]);

  const [cost, setCost] = useState(0);
  const [isSurgery, setIsSurgery] = React.useState(false);
  const [care, setCare] = React.useState(null);
  const [context, setContext] = useState('');
  const { data } = useContext(Context);

  let steps = [];

  useEffect(() => {
    setActiveStep(0);
    dispatch(clearState());
  }, [dispatch]);

  const handleNext = (data) => {
    if (data) {
      switch (activeStep) {
        case 0:
          dispatch(selectType('quote'));
          dispatch(selectAnimal(data));
          break;
        case 1:
          dispatch(selectCare(care));
          dispatch(selectIsSurgery(isSurgery));
          break;
        case 2:
          dispatch(selectCost(cost));
          break;
        case 3:
          dispatch(selectContext(context));
          setFiles([]);
          break;
        default:
          break;
      }
    }
    setActiveStep((prevActiveStep) => prevActiveStep + 1);
  };

  useEffect(() => {
    if (status === 'succeeded') dispatch(getAccount());
  }, [status, dispatch]);

  const handleBack = () => {
    dispatch(clearStatus());
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
  };

  steps = [
    {
      label: t('steps.companion'),
      description: <Step1 data={data} handleNext={handleNext} />,
    },
    {
      label: t('steps.cause'),
      description: (
        <Step2
          care={care}
          isQuote={true}
          isSurgery={isSurgery}
          setCare={setCare}
          setIsSurgery={setIsSurgery}
        />
      ),
    },
    {
      label: t('steps.amount'),
      description: <Step3 isQuote={true} setCost={setCost} />,
    },
    {
      label: t('steps.context'),
      description: (
        <Step5 isQuote={true} setContext={[context, setContext]} />
      ),
    },
    {
      label: t('steps.data'),
      description: <Step6 isQuote={true} files={[files, setFiles]} />,
    },
  ];

  useEffect(() => {
    if (activeStep === steps.length) dispatch(refundSend(files));
  }, [dispatch, activeStep, steps.length, files]);

  const action =
    activeStep === 0 || activeStep === steps.length ? null : (
      <>
        <Button onClick={handleBack} className={classes.btnAction}>
          {t('prevButton')}
        </Button>
        {activeStep === steps.length - 1 ? (
          <Button disabled={files.length === 0} onClick={handleNext}>
            {t('sendButton')}
          </Button>
        ) : activeStep > 0 ? (
          <Button
            disabled={
              (activeStep === steps.length - 3 && cost === 0) ||
              (activeStep === steps.length - 2 &&
                context.length < 10) ||
              (activeStep === steps.length - 4 && !care)
            }
            onClick={handleNext}
          >
            {t('nextButton')}
          </Button>
        ) : null}
      </>
    );
  const [width, setWidth] = useState(window.innerWidth);

  function handleWindowSizeChange() {
    setWidth(window.innerWidth);
  }

  useEffect(() => {
    window.addEventListener('resize', handleWindowSizeChange);
    return () => {
      window.removeEventListener('resize', handleWindowSizeChange);
    };
  }, []);

  const isMobile = width < 768;

  if (data?.active_contract?.paymentDue) navigate('/account');

  return data?.contracts?.filter(
    (contract, index) =>
      contract.contractType !== 'guard' &&
      !contract.isResiliated &&
      contract.items,
  ).length !== 0 ? (
    <Widget
      color="white"
      action={action}
      mobileHeight={'calc(100% - (46px + env(safe-area-inset-top)))'}
      contentMargin={isMobile ? '0px' : null}
    >
      <Stepper activeStep={activeStep} className={classes.stepper}>
        {steps.map((step) => (
          <Step key={step.label} className={classes.stepNum}>
            <StepLabel>
              <Hidden smDown>{step.label}</Hidden>
            </StepLabel>
          </Step>
        ))}
      </Stepper>
      <div className={classes.content}>
        {activeStep === steps.length ? (
          status === 'succeeded' ? (
            <>
              <CheckCircleOutlineIcon
                color="success"
                className={classes.check}
              />
              <Typography sx={{ mt: 2, mb: 1, ml: 2, mr: 2 }}>
                {t('status.success')}
              </Typography>
            </>
          ) : status === 'failed' ? (
            <>
              <ErrorOutlineIcon
                color="error"
                className={classes.check}
              />
              <Typography sx={{ mt: 2, mb: 1 }}>
                {t('status.failed')}
              </Typography>
            </>
          ) : (
            <>
              <Typography sx={{ mt: 2, mb: 1 }}>
                {t('status.loading')}
              </Typography>
            </>
          )
        ) : (
          <>{steps[activeStep].description}</>
        )}
      </div>
    </Widget>
  ) : (
    <Widget
      color="white"
      modal
      mobileHeight={'calc(100% - (46px + env(safe-area-inset-top)))'}
    >
      <h1
        style={{
          fontFamily: 'Supply',
          color: blue,
          fontSize: 'clamp(1.8rem, 0.74rem + 1.88vw, 3rem)',
          margin: 'auto',
        }}
      >
        {t('noActiveContract')}
      </h1>
    </Widget>
  );
}
