import { datadogRum } from '@datadog/browser-rum';
import * as Sentry from '@sentry/react';
import axios from 'axios';

const baseURL = process.env.REACT_APP_BACKEND_URL;

const accessToken = localStorage.getItem('access_token') || null;
const provider = process.env.REACT_APP_PROVIDER;

const axiosInstance = axios.create({
  baseURL: baseURL,
  timeout: 0,
  headers: {
    Authorization: accessToken ? 'JWT ' + accessToken : null,
    'Content-Type': 'application/json',
    Accept: 'application/json',
    Provider: provider,
  },
});

Date.prototype.toJSON = function () {
  const hoursDiff = this.getHours() - this.getTimezoneOffset() / 60;
  this.setHours(hoursDiff);
  return this.toISOString();
};

axiosInstance.interceptors.response.use(
  (response) => response,
  (error) => {
    const originalRequest = error.config;

    // Prevent infinite loops
    if (
      error.response?.status === 401 &&
      originalRequest?.url === baseURL + 'token/refresh/'
    ) {
      window.location.href = '/login';
      return Promise.reject(error);
    }

    if (
      error.response?.status === 401 &&
      error.response.data?.code === 'token_not_valid'
    ) {
      const refreshToken = localStorage.getItem('refresh_token');

      if (refreshToken && refreshToken.split('.')[1]) {
        const tokenParts = JSON.parse(
          atob(refreshToken.split('.')[1]),
        );

        // exp date in token is expressed in seconds, while now() returns milliseconds:
        const now = Math.ceil(Date.now() / 1000);

        if (tokenParts.exp > now) {
          return axiosInstance
            .post('/token/refresh/', { refresh: refreshToken })
            .then((response) => {
              localStorage.setItem(
                'access_token',
                response.data.access,
              );
              localStorage.setItem(
                'refresh_token',
                response.data.refresh,
              );

              axiosInstance.defaults.headers['Authorization'] =
                'JWT ' + response.data.access;
              originalRequest.headers['Authorization'] =
                'JWT ' + response.data.access;

              return axiosInstance(originalRequest);
            })
            .catch((err) => {
              console.log(err);
            });
        } else {
          localStorage.removeItem('access_token');
          localStorage.removeItem('refresh_token');

          axiosInstance.defaults.headers['Authorization'] = null;
          originalRequest.headers['Authorization'] = null;

          return axiosInstance(originalRequest);
        }
      } else {
        window.location.href = '/login';
      }
    }
    const apiErrorInfo = {
      'API Request': {
        URL: `${originalRequest?.baseURL}${originalRequest?.url}`,
        Method: originalRequest?.method?.toUpperCase(),
        Data: originalRequest?.data?.slice(0, 500),
      },
      'API Response': {
        Status: error.response?.status,
        'Status text': error.response?.statusText?.slice(0, 500),
        Data: JSON.stringify(error.response?.data)?.slice(0, 500),
      },
    };
    const errorEvent = {
      message: `${error?.name} - ${error.message} on ${originalRequest.url}`,
      level: 'error',
      extra: apiErrorInfo,
      /*request: {
                url: request?.url,
                method: request?.method,
                data: request?.body,
            }*/
    };
    Sentry.captureEvent(errorEvent);

    // specific error handling done elsewhere
    return Promise.reject(error);
  },
);

export const isAuthenticated = () => {
  const token = localStorage.getItem('access_token');
  return !!token;
};

export const logoutUser = () => {
  if (
    process.env.NODE_ENV === 'production' &&
    process.env.REACT_APP_ENVIRONMENT === 'production'
  ) {
    datadogRum.clearUser();
  }
  localStorage.removeItem('access_token');
  localStorage.removeItem('refresh_token');
  window.location.href = '/';
};

// eyJ0eXAiOiJKV1QiLCJhbGciOiJIUzI1NiJ9.eyJ0b2tlbl90eXBlIjoicmVmcmVzaCIsImV4cCI6MTY1MzEyMzg3MSwianRpIjoiN2E4NDkxMjJlMzU2NGI5ZThlYTA2ZGE1M2Y2ZWM2NmEiLCJ1c2VyX2lkIjoxNX0.InLeU4ODZ2p9jhFn5dHcehRxx2HDX6jECIaa5Ehs-ek

export default axiosInstance;
