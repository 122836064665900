import { makeStyles } from '@mui/styles';
import React, { useContext, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';

import { Context } from '../../../../layouts/Account';
import { blue } from '../../../../theme';
import Widget from '../../../atoms/Widget';
import Taux from './Taux';

const useStyles = makeStyles((theme) => ({
  title: {
    color: blue,
    margin: '10 0',
    fontSize: 'clamp(1.5rem, 1.4rem + 0.5vw, 2rem)',
  },
}));

export default function Garanties() {
  const { t } = useTranslation(null, {
    keyPrefix: 'accountPage.guarantees',
  });
  const classes = useStyles();
  const { data, handleClose, refreshAccount } = useContext(Context);
  const navigate = useNavigate();
  const [width, setWidth] = useState(window.innerWidth);

  const onClose = () => {
    setTimeout(() => {
      refreshAccount();
    }, 2000);
    handleClose();
  };

  function handleWindowSizeChange() {
    setWidth(window.innerWidth);
  }
  useEffect(() => {
    window.addEventListener('resize', handleWindowSizeChange);
    return () => {
      window.removeEventListener('resize', handleWindowSizeChange);
    };
  }, []);
  const isSmallDevice = width < 600;

  if (data?.active_contract?.paymentDue) navigate('/account');

  return (
    <Widget
      mobileHeight={'calc(100% - (46px + env(safe-area-inset-top)))'}
      contentMargin={isSmallDevice ? '6px' : null}
    >
      <h1 className={classes.title}>{t('title')}</h1>

      {!data.active_contract ||
      data.active_contract.provider === 'wakam' ? (
        //no contract (not possible)
        <h5>
          {data.active_contract.provider === 'wakam'
            ? t('changeWakamContract')
            : t('noActiveContract')}{' '}
          {process.env.REACT_APP_PHONE_NUMBER}
        </h5>
      ) : (
        <Taux contracts={data.active_contract} close={onClose} />
      )}
    </Widget>
  );
}
