import { Alert } from '@mui/material';
import Grid from '@mui/material/Grid';
import Paper from '@mui/material/Paper';
import Typography from '@mui/material/Typography';
import { makeStyles } from '@mui/styles';
import fileDownload from 'js-file-download';
import React, { useContext } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';

import { Context } from '../../../../layouts/Account';
import {
  displayErrorMessage,
  displaySuccessMessage,
} from '../../../../providers/snackbar';
import cat from '../../../../static/cat.svg';
import dog from '../../../../static/dog.svg';
import { blue } from '../../../../theme';
import { getErrorTranslation } from '../../../../utils/language';
import Button from '../../../atoms/Button';
import Widget from '../../../atoms/Widget';
import axiosAPI from '../../../axiosApi';
import Popup from '../../../molecules/Dialog';
import Popover from '../../../molecules/Popover';
import PaymentSchedule from '../garanties/PaymentSchedule';
import MobileContracts from './MobileContracts';

const useStyles = makeStyles((theme) => ({
  separatorH: {
    color: blue,
    borderRight: 'dashed 7px',
    borderLeft: 'none',
  },
  taux: {
    display: 'flex',
    justifyContent: 'space-around',
    flexWrap: 'wrap',
  },
  separator: {
    color: blue,
    borderTop: 'dashed 7px',
    borderBottom: 'none',
  },
  vseparator: {
    color: blue,
    border: 'none',
    borderLeft: '7px dashed',
    width: '1px',
    height: '100%',
  },
  img: {
    height: '110px',
    width: '110px',
    display: 'flex',
    margin: 'auto',
  },
  paper: {
    width: '190px',
    maxWidth: '190px',
    position: 'relative',
    boxShadow: '0 0 15px rgba(0,0,0,.1)',
  },
  title: {
    color: blue,
    margin: 0,
  },
  subtitle: {
    maxWidth: '190px',
    color: 'rgb(179, 179, 179)',
    margin: 0,
  },
  ribbon: {
    overflow: 'hidden',
    position: 'absolute',
    width: '105px',
    height: '100px',
    '&:before, &:after': {
      position: 'absolute',
      zIndex: '-1',
      content: '""',
      display: 'block',
      border: '5px solid #2980b9',
      borderTopColor: 'transparent',
      borderLeftColor: 'transparent',
    },
    '& span': {
      position: 'absolute',
      display: 'block',
      padding: '5px 0',
      width: '120px',
      backgroundColor: '#3498db',
      boxShadow: '0 5px 10px rgba(0,0,0,.1)',
      color: '#fff',
      fontSize: '13px',
      textShadow: '0 1px 1px rgba(0,0,0,.2)',
      textTransform: 'uppercase',
      textAlign: 'center',
      left: '-30px',
      top: '15px',
      transform: 'rotate(-45deg)',
    },
    '&:before': {
      top: 0,
      right: 0,
    },
    '&:after': {
      bottom: 0,
      left: 0,
    },
  },
  redRibbon: {
    '& span': {
      backgroundColor: 'red',
    },
  },
}));

export default function Garanties() {
  const { t } = useTranslation(null, {
    keyPrefix: 'accountPage.contracts',
  });
  const classes = useStyles();
  const [width, setWidth] = React.useState(window.innerWidth);
  const [isLoading, setIsLoading] = React.useState(false);
  const { data, refreshAccount } = useContext(Context);
  const navigate = useNavigate();

  const contract = data.contracts
    .filter((a) => a.status !== 'pending')
    .sort((a, b) => (a.started_at > b.started_at ? -1 : 1))[0];
  const healthContracts = contract.items.filter(
    (item) => item.contractItemType === 'health',
  );
  const prevContracts = contract.items.filter(
    (item) => item.contractItemType === 'prev',
  );
  const guardContracts = contract.items.filter(
    (item) => item.contractItemType === 'guard',
  );

  const [open, setOpen] = React.useState(false);
  const handleClose = () => {
    setOpen(false);
  };
  const [title, setTitle] = React.useState();
  const [done, setDone] = React.useState(false);
  const [action, setAction] = React.useState();
  const [content, setContent] = React.useState();

  function handleWindowSizeChange() {
    setWidth(window.innerWidth);
  }

  React.useEffect(() => {
    window.addEventListener('resize', handleWindowSizeChange);
    return () => {
      window.removeEventListener('resize', handleWindowSizeChange);
    };
  }, []);

  async function handleDownload(item) {
    setIsLoading(true);
    await axiosAPI
      .get(`downloadSchedule/${item.id}/`, { responseType: 'blob' })
      .then((response) => {
        fileDownload(response.data, 'echeancier.pdf');
      })
      .catch((err) => {
        displayErrorMessage(getErrorTranslation(err?.response?.data));
      });
    setIsLoading(false);
  }

  const isMobile = width < 1000;

  async function confirmResiliate(id) {
    setAction(() => () => resiliate(id));
    setTitle(t('confirmResiliate.title'));
    setIsLoading(true);

    const healthContract = healthContracts.length
      ? healthContracts.filter((e) => e.id === id)
      : [];
    let preventionContractsNotResiliated = [];
    if (healthContract.length && 'animal' in healthContract[0]) {
      preventionContractsNotResiliated = prevContracts.length
        ? prevContracts.filter(
            (e) =>
              e.animal.id === healthContract[0].animal.id &&
              e.resiliated_at === null,
          )
        : [];
    }
    await axiosAPI
      .post('preResiliate/', { id: id })
      .then((response) => {
        setContent(
          <React.Fragment>
            {preventionContractsNotResiliated.length ? (
              <Alert severity="info">
                {t('preResiliate.info')}
              </Alert>
            ) : (
              ''
            )}
            <Typography gutterBottom>
              <p>{t('preResiliate.text1')}</p>
              <p>
                {t('preResiliate.text2')}
                {new Date(response.data.date).toLocaleDateString()}.
              </p>
              {response.data.refund !== 0 &&
              new Date(response.data.date).toLocaleDateString() ===
                new Date().toLocaleDateString() ? (
                <p>
                  {t('preResiliate.text3')}
                  {response.data.refund
                    .toFixed(2)
                    .replace('.', ',')}{' '}
                  €.
                </p>
              ) : (
                ''
              )}
            </Typography>
          </React.Fragment>,
        );
        setDone(false);
        setOpen(true);
      })
      .catch((error) => {
        setTitle(t('preResiliate.titleError'));
        setContent(
          <Typography gutterBottom>
            {t('preResiliate.textError')}
          </Typography>,
        );
      });
    setIsLoading(false);
  }

  async function resiliate(id) {
    setIsLoading(true);
    await axiosAPI
      .post('resiliate/', { id: id })
      .then((response) => {
        displaySuccessMessage(t('resiliateSuccess'));
      })
      .catch((error) => {
        displayErrorMessage(t('resiliateError'));
      })
      .finally(() => refreshAccount());
    setIsLoading(false);
  }

  function confirmCancel(id, date) {
    const preventionContract = prevContracts.length
      ? prevContracts.filter((e) => e.id === id)
      : [];
    let hasHealthContractInResiliation = [];
    if (
      preventionContract.length &&
      'animal' in preventionContract[0]
    ) {
      hasHealthContractInResiliation = healthContracts.length
        ? healthContracts.filter(
            (e) =>
              e.animal.id === preventionContract[0].animal.id &&
              e.resiliated_at !== null,
          )
        : [];
    }

    setAction(() => () => cancelResiliate(id));
    setTitle(t('confirmCancel.title'));
    setContent(
      <React.Fragment>
        {hasHealthContractInResiliation.length ? (
          <Alert severity="info">{t('confirmCancel.info')}</Alert>
        ) : (
          ''
        )}
        <Typography gutterBottom>
          <p>{t('confirmCancel.areYouSure')}</p>
          <p>
            {t('confirmCancel.cancelPlanning')}
            {new Date(date).toLocaleDateString()}.
          </p>
        </Typography>
      </React.Fragment>,
    );
    setDone(false);
    setOpen(true);
  }

  async function cancelResiliate(id) {
    setIsLoading(true);
    await axiosAPI
      .post('cancelResiliate/', { id: id })
      .then((response) => {
        setDone(true);
        setTitle(t('cancelResiliate.title'));
        setContent(
          <Typography gutterBottom>
            {t('cancelResiliate.textSuccess')}
          </Typography>,
        );
      })
      .catch((error) => {
        setTitle(t('cancelResiliate.errorTitle'));
        setContent(
          <Typography gutterBottom>
            {t('cancelResiliate.errorText')}
          </Typography>,
        );
      })
      .finally(() => refreshAccount());
    setIsLoading(false);
  }

  if (!contract || contract.length === 0) {
    return (
      <Widget
        mobileHeight={
          'calc(100% - (46px + env(safe-area-inset-top)))'
        }
      >
        <h1
          style={{
            fontFamily: 'Supply',
            color: blue,
            fontSize: 'clamp(1.8rem, 0.74rem + 1.88vw, 3rem)',
            margin: 'auto',
          }}
        >
          {t('noActiveContract')}
        </h1>
      </Widget>
    );
  }

  if (data?.active_contract?.paymentDue) navigate('/account');

  return (
    <Widget
      mobileHeight={'calc(100% - (46px + env(safe-area-inset-top)))'}
    >
      <Popup
        open={open}
        onClose={handleClose}
        aria-labelledby="customized-dialog-title"
        title={title}
        action={action}
        done={done}
        status={isLoading ? 'loading' : ''}
      >
        {content}
      </Popup>

      {isMobile ? (
        <MobileContracts
          contracts={contract}
          confirmCancel={confirmCancel}
          confirmResiliate={confirmResiliate}
          t={t}
        />
      ) : (
        <>
          <h1 className={classes.title}>{t('title')}</h1>
          <Grid container>
            {healthContracts.length !== 0 && (
              <Grid item xs={prevContracts.length !== 0 ? 5 : 12}>
                <h2>{t('healthContract')}</h2>
                <div className={classes.taux}>
                  {healthContracts.map((item, index) => (
                    <div key={index}>
                      <Paper className={classes.paper}>
                        {item.resiliated_at ? (
                          new Date(item.resiliated_at) <
                          new Date(Date.now() - 864e5) ? (
                            <div
                              className={`${classes.ribbon} ${classes.redRibbon}`}
                            >
                              <span style={{ fontSize: '13px' }}>
                                {t('resiliated')}
                              </span>
                            </div>
                          ) : (
                            <div className={classes.ribbon}>
                              <span style={{ fontSize: '10px' }}>
                                {t('resiliation')}
                              </span>
                            </div>
                          )
                        ) : (
                          ''
                        )}
                        <img
                          className={classes.img}
                          src={
                            item.animal.breed.species === 'dog'
                              ? dog
                              : cat
                          }
                          alt={item.animal.name}
                        />
                        <Popover
                          style={{
                            position: 'absolute',
                            top: '10px',
                            right: '2px',
                          }}
                        >
                          <PaymentSchedule schedule={item.schedule} />
                          <Button
                            onClick={() => handleDownload(item)}
                          >
                            {t('downloadButton')}
                          </Button>
                        </Popover>
                        <Typography
                          variant={'h7'}
                          color={'primary'}
                          className={classes.typo}
                        >
                          {item.animal.name} (
                          {Number.isInteger(item.price)
                            ? item.price
                            : item.price.toFixed(2)}
                          €)
                        </Typography>
                      </Paper>
                      {item.resiliated_at ? (
                        new Date(item.resiliated_at) <
                        new Date(Date.now() - 864e5) ? (
                          <h4 className={classes.subtitle}>
                            {t('endedOn')}&nbsp;
                            {new Date(
                              item.resiliated_at,
                            ).toLocaleDateString()}
                          </h4>
                        ) : (
                          <>
                            {new Date(item.resiliated_at) >
                              new Date(Date.now()) && (
                              <Button
                                onClick={() =>
                                  confirmCancel(
                                    item.id,
                                    item.resiliated_at,
                                  )
                                }
                              >
                                {t('cancelButton')}
                              </Button>
                            )}
                            <h4 className={classes.subtitle}>
                              {t('endOn')}&nbsp;
                              {new Date(
                                item.resiliated_at,
                              ).toLocaleDateString()}
                            </h4>
                          </>
                        )
                      ) : (
                        <Button
                          onClick={() => confirmResiliate(item.id)}
                        >
                          {t('resiliateButton')}
                        </Button>
                      )}
                    </div>
                  ))}
                </div>
              </Grid>
            )}

            {healthContracts.length !== 0 &&
              prevContracts.length !== 0 && (
                <Grid item xs={2}>
                  <hr className={classes.vseparator} />
                </Grid>
              )}

            {prevContracts.length !== 0 && (
              <Grid item xs={healthContracts.length !== 0 ? 5 : 12}>
                <h2>{t('prevOption')}</h2>
                <div className={classes.taux}>
                  {prevContracts.map((item, index) => (
                    <div key={index}>
                      <Paper className={classes.paper}>
                        {item.resiliated_at ? (
                          new Date(item.resiliated_at) <
                          new Date(Date.now() - 864e5) ? (
                            <div
                              className={`${classes.ribbon} ${classes.redRibbon}`}
                            >
                              <span style={{ fontSize: '13px' }}>
                                {t('resiliated')}
                              </span>
                            </div>
                          ) : (
                            <div className={classes.ribbon}>
                              <span style={{ fontSize: '10px' }}>
                                {t('resiliation')}
                              </span>
                            </div>
                          )
                        ) : (
                          ''
                        )}
                        <img
                          className={classes.img}
                          src={
                            item.animal.breed.species === 'dog'
                              ? dog
                              : cat
                          }
                          alt={item.animal.name}
                        />
                        <Popover
                          style={{
                            position: 'absolute',
                            top: '10px',
                            right: '2px',
                          }}
                        >
                          <PaymentSchedule schedule={item.schedule} />
                          <Button
                            onClick={() => handleDownload(item)}
                          >
                            {t('downloadButton')}
                          </Button>
                        </Popover>
                        <Typography
                          variant={'h7'}
                          color={'primary'}
                          className={classes.typo}
                        >
                          {item.animal.name} (
                          {Number.isInteger(item.price)
                            ? item.price
                            : item.price.toFixed(2)}
                          €)
                        </Typography>
                      </Paper>
                      {item.resiliated_at ? (
                        new Date(item.resiliated_at) <
                        new Date(Date.now() - 864e5) ? (
                          <h4 className={classes.subtitle}>
                            {t('endedOn')}
                            {new Date(
                              item.resiliated_at,
                            ).toLocaleDateString()}
                          </h4>
                        ) : (
                          <>
                            {new Date(item.resiliated_at) >
                              new Date(Date.now()) && (
                              <Button
                                onClick={() =>
                                  confirmCancel(
                                    item.id,
                                    item.resiliated_at,
                                  )
                                }
                              >
                                {t('cancelButton')}
                              </Button>
                            )}
                            <h4 className={classes.subtitle}>
                              {t('endOn')}
                              {new Date(
                                item.resiliated_at,
                              ).toLocaleDateString()}
                            </h4>
                          </>
                        )
                      ) : (
                        <Button
                          onClick={() => confirmResiliate(item.id)}
                        >
                          {t('resiliateButton')}
                        </Button>
                      )}
                    </div>
                  ))}
                </div>
              </Grid>
            )}

            {(healthContracts.length !== 0 ||
              prevContracts.length !== 0) &&
              guardContracts.length !== 0 && (
                <Grid item xs={12}>
                  <hr className={classes.separator} />
                </Grid>
              )}

            {guardContracts.length !== 0 && (
              <Grid item xs={12}>
                <h2>{t('prevContract')}</h2>
                <div className={classes.taux}>
                  {guardContracts.map((item, index) => (
                    <div key={index}>
                      <Paper className={classes.paper}>
                        {item.resiliated_at ? (
                          new Date(item.resiliated_at) <
                          new Date(Date.now() - 864e5) ? (
                            <div
                              className={`${classes.ribbon} ${classes.redRibbon}`}
                            >
                              <span style={{ fontSize: '13px' }}>
                                {t('resiliated')}
                              </span>
                            </div>
                          ) : (
                            <div className={classes.ribbon}>
                              <span style={{ fontSize: '10px' }}>
                                {t('resiliation')}
                              </span>
                            </div>
                          )
                        ) : (
                          ''
                        )}
                        <img
                          className={classes.img}
                          src={
                            item.animal.breed.species === 'dog'
                              ? dog
                              : cat
                          }
                          alt={item.animal.name}
                        ></img>
                        <Popover
                          style={{
                            position: 'absolute',
                            top: '10px',
                            right: '2px',
                          }}
                        >
                          <PaymentSchedule schedule={item.schedule} />
                          <Button
                            onClick={() => handleDownload(item)}
                          >
                            {t('downloadButton')}
                          </Button>
                        </Popover>
                        <Typography
                          variant={'h7'}
                          color={'primary'}
                          className={classes.typo}
                        >
                          {item.animal.name} (
                          {Number.isInteger(item.price)
                            ? item.price
                            : item.price.toFixed(2)}
                          €)
                        </Typography>
                      </Paper>
                      {item.resiliated_at ? (
                        new Date(item.resiliated_at) <
                        new Date(Date.now() - 864e5) ? (
                          <h4 className={classes.subtitle}>
                            {t('endedOn')}&nbsp;
                            {new Date(
                              item.resiliated_at,
                            ).toLocaleDateString()}
                          </h4>
                        ) : (
                          <>
                            {new Date(item.resiliated_at) >
                              new Date(Date.now()) && (
                              <Button
                                onClick={() =>
                                  confirmCancel(
                                    item.id,
                                    item.resiliated_at,
                                  )
                                }
                              >
                                {t('cancelButton')}
                              </Button>
                            )}
                            <h4 className={classes.subtitle}>
                              {t('endOn')}&nbsp;
                              {new Date(
                                item.resiliated_at,
                              ).toLocaleDateString()}
                            </h4>
                          </>
                        )
                      ) : (
                        <Button
                          onClick={() => confirmResiliate(item.id)}
                        >
                          {t('resiliateButton')}
                        </Button>
                      )}
                    </div>
                  ))}
                </div>
              </Grid>
            )}
          </Grid>
        </>
      )}
    </Widget>
  );
}

// disabled={new Date(contract.created_at) > new Date(new Date().setFullYear(new Date().getFullYear() - 1))}
