import ArrowBackIosOutlinedIcon from '@mui/icons-material/ArrowBackIosOutlined';
import { Grid, Hidden, IconButton, Typography } from '@mui/material';
import Button from '@mui/material/Button';
import Step from '@mui/material/Step';
import StepLabel from '@mui/material/StepLabel';
import Stepper from '@mui/material/Stepper';
import makeStyles from '@mui/styles/makeStyles';
import { Elements } from '@stripe/react-stripe-js';
import React, { useContext, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import Lottie from 'react-lottie-player';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';

import { Context } from '../../../layouts/Account';
import { LanguageContext } from '../../../providers/context/language';
import levitation_chat from '../../../static/anims/levitation-chat.json';
import pricing_asset_mobile_3 from '../../../static/pricing/pricing-asset-mobile-3.svg';
import { isAlcampo } from '../../../utils/language';
import {
  loadStripeOney,
  loadStripeWakam,
} from '../../../utils/stripeLoader';
import FabPrice from '../../atoms/FabPrice';
import Widget from '../../atoms/Widget';
import AnimalAdditionalInfo from '../pricing/animalAdditionalInfo';
import AnimalBirthday from '../pricing/animalBirthday';
import AnimalBreed from '../pricing/animalBreed';
import AnimalGender from '../pricing/animalGender';
import AnimalName from '../pricing/animalName';
import AnimalSelection from '../pricing/animalSelection';
import Birthday from '../pricing/birthday';
import {
  GUARD_TYPE_PREVENTION,
  GUARD_TYPE_PREVOYANCE,
  MULTIPLE,
} from '../pricing/constants';
import Guard from '../pricing/guard';
import {
  completeFormPricing,
  formPricing,
  reset,
  selectAnimals,
  selectAnimalType,
  selectBirthday,
  selectFirstName,
  selectLastName,
  selectPreventionOrInsurance,
} from '../pricing/pricingSlice';
import StripeProvider from '../quote/StripeProvider';
import Refund1 from './refunds/Step1';
import SelectQuotePricing from './SelectQuotePricing';
import SelectQuoteType from './SelectQuoteType';

const useStyles = makeStyles((theme) => ({
  content: {
    width: '100%',
    height: '100%',
    '&>img': {
      width: '100%',
      height: '100%',
    },
    textAlign: '-webkit-center',
  },
  title_text: {
    fontSize: '2.1rem',
    padding: 20,
    [theme.breakpoints.down('lg')]: {
      fontSize: '1.7rem',
    },
    [theme.breakpoints.down('md')]: {
      fontSize: '1.4rem',
    },
    [theme.breakpoints.down('sm')]: {
      fontSize: '1.2rem',
      color: 'white',
      backgroundImage: `url(${pricing_asset_mobile_3})`,
      backgroundRepeat: 'no-repeat',
      backgroundPosition: 'center',
    },
  },
  swipeContainer: {
    height: '100%',
    '& > *': {
      height: '100%',
    },
  },
  button: {
    marginRight: theme.spacing(1),
  },
  backButton: {
    marginRight: theme.spacing(1),
  },
  linearProgress: {
    position: 'fixed',
    top: 80,
    left: 0,
    width: '100%',
    zIndex: 1000,
  },
  colorPrimary: {
    backgroundColor: 'rgba(1,1,1,0)',
  },
  slideContainer: {
    height: '300px',
  },
  stepper: {
    marginTop: '10px',
    marginBottom: '10px',
  },
  stepNum: {
    '& text': {
      fill: 'white',
    },
  },
  price: {
    [theme.breakpoints.down('sm')]: {
      transform: 'scale(0.7)',
      fontSize: '13.9px',
    },
  },
}));

function Quote(props) {
  const { t } = useTranslation(null, {
    keyPrefix: 'accountPage.addContract.quote',
  });
  const classes = useStyles();
  const { contract, handleNext } = props;
  const { prices, quoteType, quotePricing } = useSelector(
    (state) => state.pricing,
  );
  let price = 0;

  if (contract && prices) {
    if (props.guard) {
      price =
        quoteType === GUARD_TYPE_PREVENTION
          ? prices['prev'][quotePricing || contract.prev]
          : prices['total'][contract.recursivity][
              contract.annuity.toString()
            ];
    } else {
      if (contract?.option && prices.option?.total) {
        price =
          prices['option']['total'][contract.recursivity][
            (contract.refund * 100).toString()
          ];
        if (contract.provider !== 'wakam') {
          price =
            price[contract.excess.toString()][
              contract.ceil.toString()
            ][contract.annuity.toString()];
          if (contract.prevention) {
            const preventionPrice = prices['prev'][contract.prev];
            if (!isNaN(preventionPrice)) price += preventionPrice;
          }
        } else {
          price = price[contract.annuity.toString()];
        }
      } else if (prices.base?.total) {
        price =
          prices['base']['total'][contract.recursivity][
            (contract.refund * 100).toString()
          ];
        if (contract.provider !== 'wakam') {
          price =
            price[contract.excess.toString()][
              contract.ceil.toString()
            ];
          if (contract.prevention) {
            const preventionPrice = prices['prev'][contract.prev];
            if (!isNaN(preventionPrice)) price += preventionPrice;
          }
        }
      }
    }
  }
  if (price) {
    return (
      <Grid
        container
        justifyContent="flex-start"
        sx={{ height: '100%' }}
      >
        <Grid item xs={12} style={{ padding: '20px' }}>
          <Typography
            color="primary"
            variant={'h4'}
            className={classes.title_text}
          >
            <Hidden smDown>
              {t('title1')}
              <span style={{ display: 'inline-block' }}>
                {t('title2')}{' '}
              </span>
            </Hidden>
            <Hidden smUp>{t('mobile.title1')} </Hidden>
          </Typography>
        </Grid>

        <Grid item xs={12}>
          <FabPrice
            className={classes.price}
            price={price}
            an={contract.recursivity}
            symbol="€"
            fontSize="2rem"
            size="200px"
            disabled
          />
        </Grid>
        <Grid item xs={12}>
          <Button
            variant={'contained'}
            sx={{
              width: { xs: 'unset', md: '210px' },
              height: { xs: 'unset', md: '62px' },
              padding: { xs: '8px 16px', md: 'unset' },
              margin: 'auto',
              marginBottom: '10px',
              clipPath:
                'polygon(2px 0, 0 62px, 337px 60px, 330px 3px, 2px 0)',
              borderRadius: 0,
            }}
            onClick={() => {
              handleNext();
            }}
          >
            {t('chooseButton')}
          </Button>
        </Grid>
      </Grid>
    );
  } else
    return (
      <Grid
        container
        justifyContent="flex-start"
        alignItems="center"
        sx={{ height: '100%' }}
      >
        <Lottie
          animationData={levitation_chat}
          play={true}
          loop={true}
          style={{
            position: 'relative',
            margin: '0 auto',
            padding: 0,
          }}
        />
      </Grid>
    );
}
Quote.componentName = 'stepLabels.tarif';

export default function AddContract(props) {
  const [stripeOney, setStripeOney] = useState(null);
  const [stripeWakam, setStripeWakam] = useState(null);
  useEffect(() => {
    const fetchOney = async () => await loadStripeOney();
    const fetchWakam = async () => await loadStripeWakam();
    const oneyStripeProvider = fetchOney().catch(console.error);
    const wakamStripeProvider = fetchWakam().catch(console.error);
    setStripeOney(oneyStripeProvider);
    setStripeWakam(wakamStripeProvider);
  }, []);
  const { t } = useTranslation(null, {
    keyPrefix: 'accountPage.addContract',
  });
  const { language } = useContext(LanguageContext);
  const navigate = useNavigate();
  const classes = useStyles();
  const dispatch = useDispatch();
  const [activeStep, setActiveStep] = React.useState(0);
  const [previousSteps, setPreviousSteps] = React.useState([]);
  const [onlyPayment, setOnlyPayment] = React.useState(false);
  const { birthday } = useSelector((state) => state.pricing);
  const quoteType = useSelector((state) => state.pricing.quoteType);
  const { data, refreshAccount } = useContext(Context);

  const contract = data?.active_contract;

  useEffect(() => {
    if (activeStep === 0) {
      dispatch(reset());
    }
  }, [dispatch, activeStep]);

  const pushStep = (step) => {
    setPreviousSteps([...previousSteps, activeStep]);
    setActiveStep(step);
  };

  const popStep = () => {
    let previousStepsCopy = [...previousSteps];
    setActiveStep(previousStepsCopy.pop());
    setPreviousSteps(previousStepsCopy);
  };

  const views = props.guard
    ? data?.birthday
      ? contract.provider === 'oney'
        ? [
            SelectQuoteType,
            SelectQuotePricing,
            Refund1,
            Guard,
            Quote,
            StripeProvider,
          ]
        : [Refund1, Guard, Quote, StripeProvider]
      : contract.provider === 'oney'
      ? [
          SelectQuoteType,
          SelectQuotePricing,
          Refund1,
          Guard,
          Birthday,
          Quote,
          StripeProvider,
        ]
      : [Refund1, Guard, Birthday, Quote, StripeProvider]
    : [
        AnimalSelection,
        AnimalName,
        AnimalGender,
        AnimalBreed,
        AnimalBirthday,
        AnimalAdditionalInfo,
        Quote,
        StripeProvider,
      ];
  const totalSteps = views.length;
  const handleNext = (formData) => {
    const birthdayDate =
      data && 'birthday' in data && data?.birthday?.length
        ? data?.birthday
        : formData?.birthday
        ? formData?.birthday
        : birthday;
    if (activeStep < totalSteps - 3) {
      dispatch(formPricing(formData));
    } else if (activeStep === totalSteps - 3) {
      dispatch(formPricing(formData)).then(() =>
        dispatch(
          completeFormPricing({
            step: 6,
            phone: data?.phone,
            email: data?.email,
            birthday: birthdayDate,
            addItem: true,
            language: language,
            affiliateExternalId: isAlcampo()
              ? process.env.REACT_APP_ALCAMPO_PROVIDER_EXTERNAL_ID
              : null,
          }),
        ),
      );
    } else if (activeStep === totalSteps - 2) setOnlyPayment(true);

    if (props.guard) {
      if (
        activeStep === 0 &&
        (formData.quoteType === GUARD_TYPE_PREVOYANCE ||
          (formData.quoteType === GUARD_TYPE_PREVENTION &&
            contract.prev))
      ) {
        pushStep(activeStep + 2);
      } else if (
        activeStep === 2 &&
        quoteType === GUARD_TYPE_PREVENTION
      ) {
        dispatch(formPricing(formData)).then(() =>
          dispatch(
            completeFormPricing({
              step: 6,
              phone: data?.phone,
              email: data?.email,
              birthday: birthdayDate,
              addItem: true,
              language: language,
              affiliateExternalId: isAlcampo()
                ? process.env.REACT_APP_ALCAMPO_PROVIDER_EXTERNAL_ID
                : null,
            }),
          ),
        );
        pushStep(totalSteps - 2);
      } else {
        pushStep(activeStep + 1);
      }
    } else {
      pushStep(activeStep + 1);
    }
    window.scrollTo(0, 0);
  };

  const handlePrevious = () => {
    if (previousSteps.length > 0) {
      popStep();
      setOnlyPayment(false);
    }
  };

  useEffect(() => {
    if (contract && activeStep === 0) {
      dispatch(selectFirstName(data?.first_name));
      dispatch(selectLastName(data?.last_name));
      dispatch(selectPreventionOrInsurance(null));
      if (!props.guard) {
        dispatch(selectAnimalType(MULTIPLE));
      }
      dispatch(selectAnimals([]));
      if (contract.option && data?.birthday)
        dispatch(selectBirthday(data?.birthday));
      dispatch(
        formPricing({
          step: 1,
          first_name: data?.first_name,
          last_name: data?.last_name,
        }),
      )
        .then(() => {
          !props.guard
            ? dispatch(formPricing({ step: 2, quoteType: MULTIPLE }))
            : Promise.resolve();
        })
        .then(() =>
          dispatch(formPricing({ step: 5, address: data?.address })),
        )
        .then(() =>
          dispatch(
            formPricing({ step: 8, birthday: data?.birthday }),
          ),
        );
    }
  }, [
    activeStep,
    contract,
    dispatch,
    data?.first_name,
    data?.last_name,
    props.guard,
    data?.address,
    data?.phone,
    data?.email,
    data?.birthday,
  ]);
  const [width, setWidth] = useState(window.innerWidth);

  function handleWindowSizeChange() {
    setWidth(window.innerWidth);
  }

  useEffect(() => {
    window.addEventListener('resize', handleWindowSizeChange);
    return () => {
      window.removeEventListener('resize', handleWindowSizeChange);
    };
  }, []);

  const isMobile = width < 768;
  if (!contract) {
    return (
      <Widget
        color="white"
        mobileHeight={
          'calc(100% - (46px + env(safe-area-inset-top)))'
        }
        contentMargin={isMobile ? '0px' : null}
      >
        <h1
          style={{
            fontFamily: 'Supply',
            fontSize: 'clamp(1.8rem, 0.74rem + 1.88vw, 3rem)',
            margin: 'auto',
          }}
        >
          {t('noContract')}
        </h1>
      </Widget>
    );
  }

  return (
    <Widget
      color="white"
      mobileHeight={'calc(100% - (46px + env(safe-area-inset-top)))'}
      contentMargin={isMobile ? '0px' : null}
    >
      {views?.length <= 6 ? (
        <Stepper
          style={{ display: views?.length > 6 ? 'none' : 'flex' }}
          activeStep={activeStep}
          className={classes.stepper}
        >
          {views.map((step) => (
            <Step key={step.label} className={classes.stepNum}>
              <StepLabel>
                <Hidden smDown>
                  {t(step.componentName) || 'error'}
                </Hidden>
              </StepLabel>
            </Step>
          ))}
        </Stepper>
      ) : (
        <div style={{ height: '25px' }}></div>
      )}
      <div className={classes.content}>
        {activeStep > 0 && activeStep !== totalSteps - 1 ? (
          <div
            style={{
              position: 'absolute',
              display: 'flex',
              height: '90%',
            }}
          >
            <IconButton
              aria-label="Example"
              sx={{
                margin: 'auto',
                height: 'min-content',
                zIndex: 100,
              }}
              onClick={handlePrevious}
            >
              <ArrowBackIosOutlinedIcon
                sx={{ fontSize: { md: 100, xs: 40 } }}
              />
            </IconButton>
          </div>
        ) : (
          <></>
        )}
        <Elements
          stripe={
            contract.provider === 'wakam' ? stripeWakam : stripeOney
          }
        >
          <>
            {[views[activeStep]].map((Component) => (
              <Component
                isAccount={true}
                data={data}
                step={activeStep + 3}
                key={`stepper_${activeStep}`}
                handleNext={handleNext}
                onlyPayment={onlyPayment}
                contract={contract}
                provider={contract.provider}
                onComplete={() => {
                  refreshAccount();
                  navigate('/account/contracts');
                }}
                {...props}
              />
            ))}
          </>
        </Elements>
      </div>
    </Widget>
  );
}
