import ArrowBackIosOutlinedIcon from '@mui/icons-material/ArrowBackIosOutlined';
import { IconButton, LinearProgress } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import _ from 'lodash';
import React, { useContext, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate, useParams } from 'react-router-dom';
import SwipeableViews from 'react-swipeable-views';

import { LanguageContext } from '../../../providers/context/language';
import { blue_light, yellow } from '../../../theme';
import pricingTheme from '../../../theme/pricing';
import { isAlcampo } from '../../../utils/language';
import Address from './address';
import AnimalAdditionalInfo from './animalAdditionalInfo';
import AnimalBirthday from './animalBirthday';
import AnimalBreed from './animalBreed';
import AnimalGender from './animalGender';
import AnimalName from './animalName';
import AnimalSelection from './animalSelection';
import Birthday from './birthday';
import { GUARD } from './constants';
import Email from './email';
import Guard from './guard';
import Name from './name';
import {
  completeFormPricing,
  formPricing,
  reset,
} from './pricingSlice';

const useStyles = makeStyles((theme) => ({
  rootPricing: {
    width: '100%',
    height: '100vh',
    paddingTop: 90,
    [theme.breakpoints.down('sm')]: {
      paddingTop: 75,
    },
    backgroundColor: blue_light,
  },
  linearProgress: {
    position: 'fixed',
    top: 64,
    [theme.breakpoints.down('sm')]: {
      top: 46,
    },
    width: '100%',
    zIndex: 1000,
  },
  backButton: {
    paddingTop: '64px',
    top: 0,
    height: '100%',
    position: 'absolute',
    justifyContent: 'center',
    alignItems: 'center',
    display: 'flex',
    [theme.breakpoints.down('sm')]: {
      alignItems: 'flex-start',
      top: '30%',
      height: 'unset',
    },
  },
  colorPrimary: {
    backgroundColor: yellow,
  },
  arrowIcon: {
    fontSize: 40,
    color: pricingTheme.palette.primary.main,
    [theme.breakpoints.up('sm')]: {
      fontSize: 64,
      color: ({ activeStep, animalType }) => {
        if (animalType === GUARD)
          return activeStep >= 6 && activeStep < 8
            ? 'white'
            : pricingTheme.palette.primary.main;
        else
          return activeStep >= 7
            ? 'white'
            : pricingTheme.palette.primary.main;
      },
    },
  },
}));

const HEALTH_VIEWS = [
  Name,
  AnimalSelection,
  AnimalName,
  AnimalGender,
  AnimalBreed,
  AnimalBirthday,
  AnimalAdditionalInfo,
  Address,
  Email,
];
const GUARD_VIEWS = [
  Name,
  AnimalSelection,
  AnimalName,
  AnimalGender,
  AnimalBreed,
  AnimalBirthday,
  Address,
  Guard,
  Birthday,
  Email,
];

export default function HorizontalNonLinearAlternativeLabelStepper() {
  const step = parseInt(useParams()?.step);
  const { language } = useContext(LanguageContext);
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [activeStep, setActiveStep] = React.useState(0);
  const [isLoading, setIsLoading] = React.useState(false);
  const { animalType, lastName, firstName } = useSelector(
    (state) => state.pricing,
  );
  const classes = useStyles({ animalType, activeStep });
  const [views, setViews] = React.useState(
    animalType === GUARD ? GUARD_VIEWS : HEALTH_VIEWS,
  );
  const totalSteps = views.length;
  if (_.isNaN(step) || step > totalSteps || step < 0) {
    setTimeout(() => {
      navigate('/pricing/0');
    }, 500);
  } else if (step !== activeStep) {
    if (!lastName || !firstName) navigate('/pricing/0');
    else if (step === 2 && !animalType) navigate('/pricing/0');
    else setActiveStep(step);
  }

  useEffect(() => {
    if (activeStep === 0) {
      dispatch(reset());
    }
  }, [dispatch, activeStep]);

  const handleNext = (data) => {
    if (isLoading) return;
    setIsLoading(true);
    if (data.quoteType) {
      setViews(data.quoteType === GUARD ? GUARD_VIEWS : HEALTH_VIEWS);
    }
    if (data?.step < totalSteps) {
      dispatch(formPricing(data)).then((response) => {
        setIsLoading(false);
        if (response?.payload?.status === 200)
          navigate(`/pricing/${data.step}`);
      });
    } else {
      data.language = language;
      if (isAlcampo()) {
        data.affiliateExternalId =
          process.env.REACT_APP_ALCAMPO_PROVIDER_EXTERNAL_ID;
      }
      dispatch(completeFormPricing(data)).then((response) => {
        if (
          typeof window.uetq !== 'undefined' &&
          process.env.NODE_ENV !== 'development'
        ) {
          window.uetq.push('event', 'submit_lead_form', {});
        }
        setIsLoading(false);
        if (response?.payload?.status === 200) {
          setActiveStep(0);
          navigate(
            `/quote${
              response?.payload?.data?.id
                ? `?quoteId=${response.payload.data?.id}`
                : ''
            }`,
          );
        }
      });
    }
    window.scrollTo(0, 0);
  };

  const handlePrevious = () => {
    const step = activeStep - 1;
    navigate(`/pricing/${step}`);
  };
  return (
    <section sx={{ width: '100%', height: '100%' }}>
      <div className={classes.linearProgress}>
        <LinearProgress
          variant="determinate"
          value={((activeStep + 1) / totalSteps) * 100}
          classes={{ colorPrimary: classes.colorPrimary }}
        />
      </div>
      <div className={classes.rootPricing}>
        <SwipeableViews
          index={activeStep}
          disabled={true}
          style={{ height: '100%' }}
        >
          {views.map((Component, step) => (
            <Component
              step={step + 1}
              style={{ height: '100%' }}
              activeStep={activeStep}
              isLoading={isLoading}
              setIsLoading={setIsLoading}
              key={`stepper_${step}`}
              handleNext={handleNext}
              provider={process.env.REACT_APP_PROVIDER}
            />
          ))}
        </SwipeableViews>
        {activeStep > 0 ? (
          <div className={classes.backButton}>
            <IconButton onClick={() => handlePrevious()}>
              <ArrowBackIosOutlinedIcon
                className={classes.arrowIcon}
              />
            </IconButton>
          </div>
        ) : (
          <></>
        )}
      </div>
    </section>
  );
}
