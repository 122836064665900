import NotificationsIcon from '@mui/icons-material/Notifications';
import Button from '@mui/material/Button';
import Divider from '@mui/material/Divider';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemText from '@mui/material/ListItemText';
import Popover from '@mui/material/Popover';
import Typography from '@mui/material/Typography';
import { Elements } from '@stripe/react-stripe-js';
import HelloSign from 'hellosign-embedded';
import * as React from 'react';
import { useTranslation } from 'react-i18next';

import { displayErrorMessage } from '../../../../providers/snackbar';
import { blue } from '../../../../theme';
import { getErrorTranslation } from '../../../../utils/language';
import {
  loadStripeOney,
  loadStripeWakam,
} from '../../../../utils/stripeLoader';
import axiosAPI from '../../../axiosApi';
import Payment from '../admin/payment';
import Checkout from './checkout';

const NotifyMe = (props) => {
  const [stripeOney, setStripeOney] = React.useState(null);
  const [stripeWakam, setStripeWakam] = React.useState(null);
  React.useEffect(() => {
    const fetchOney = async () => await loadStripeOney();
    const fetchWakam = async () => await loadStripeWakam();
    const oneyStripeProvider = fetchOney().catch(console.error);
    const wakamStripeProvider = fetchWakam().catch(console.error);
    setStripeOney(oneyStripeProvider);
    setStripeWakam(wakamStripeProvider);
  }, []);
  // const classes = useStyles();
  // Props passed to the component
  const data = props.data;
  const { t } = useTranslation(null, {
    keyPrefix: 'accountPage.notifications',
  });

  const [anchorEl, setAnchorEl] = React.useState(null);
  const open = Boolean(anchorEl);
  const id = open ? 'simple-popover' : undefined;

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleNotifClick = (index, id, type) => {
    if (type !== 'info') {
      if (type === 'signature') {
        getModalType(index, type, id);
      } else {
        props.openModal(getModalType(index, type, id));
      }
    } else {
      data.notifications?.splice(index, 1);
      try {
        axiosAPI.post('remove_notification/', id);
      } catch (e) {
        displayErrorMessage(getErrorTranslation());
      }
    }
    handleClose();
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const getModalType = (index, type, notifID) => {
    switch (type) {
      case 'delay':
        return (
          <Elements
            stripe={
              data.active_contract.provider === 'wakam'
                ? stripeWakam
                : stripeOney
            }
          >
            <Checkout data={data} notifID={notifID} />
          </Elements>
        );
      case 'signature':
        axiosAPI
          .post('retrieveSignatureRequest/', notifID)
          .then((response) => {
            const client = new HelloSign(
              process.env.REACT_APP_HELLO_SIGN_PROD === 'true'
                ? {
                    clientId:
                      process.env.REACT_APP_HELLO_SIGN_CLIENT_ID,
                  }
                : {
                    clientId:
                      process.env.REACT_APP_HELLO_SIGN_CLIENT_ID,
                    skipDomainVerification: true,
                  },
            );

            client.on('sign', (data) => {
              data.notifications?.splice(index, 1); //supprime de la liste sans le refresh du Context (data)
            });

            client.open(response.data);
          })
          .catch((e) => displayErrorMessage(getErrorTranslation()));
        break;
      case 'payment_refused':
        return (
          <Elements
            stripe={
              data.active_contract.provider === 'wakam'
                ? stripeWakam
                : stripeOney
            }
          >
            <Checkout data={data} notifID={notifID} />
          </Elements>
        );
      case 'payment_expired':
        return (
          <Payment
            data={data}
            close={props.close}
            notifID={notifID}
            notifType={'payment'}
          />
        );
      default:
        break;
    }

    return;
  };

  return (
    <>
      <Button
        disabled={props.disabled}
        aria-describedby={id}
        variant="contained"
        onClick={handleClick}
        style={{ minWidth: '40px', width: '40px', margin: ' 0 5px' }}
      >
        <NotificationsIcon />{' '}
        {data.notifications?.length > 0
          ? data.notifications?.length
          : ''}
      </Button>

      <Popover
        id={id}
        open={open}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'left',
        }}
      >
        {data.notifications?.length > 0 ? (
          <List
            sx={{
              width: '100%',
              maxWidth: 360,
              bgcolor: 'background.paper',
            }}
          >
            {data.notifications.map((notif, index) => (
              <React.Fragment key={index}>
                <ListItem disablePadding>
                  <ListItemButton
                    onClick={(e) =>
                      handleNotifClick(
                        index,
                        notif['id'],
                        notif['action'],
                      )
                    }
                  >
                    <ListItemText
                      sx={{ color: blue }}
                      primary={notif['title']}
                      secondary={
                        <React.Fragment>
                          <Typography
                            sx={{ display: 'inline' }}
                            component="span"
                            variant="body2"
                            color="text.primary"
                          >
                            {new Date(
                              notif['created_at'],
                            ).toLocaleDateString()}
                          </Typography>
                          {' — ' + notif['label']}
                        </React.Fragment>
                      }
                    />
                  </ListItemButton>
                </ListItem>
                <Divider component="li" />
              </React.Fragment>
            ))}
          </List>
        ) : (
          <Typography sx={{ p: 2 }}>{t('noNotification')}</Typography>
        )}
      </Popover>
    </>
  );
};

export default NotifyMe;
